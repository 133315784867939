import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import requests from "../../../../../services/requests";

const RankingTableUser = ({ filterInformation, searchString, searchPeriod }) => {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const getData = () => {
        requests
            .requestRankingByUser({
                search: searchString,
                filters: filterInformation,
                period: searchPeriod,
            })
            .then(res => {
                setData(res);
                setLoad(false);
            });
    };

    useEffect(() => {
        getData();
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <table className="float-left">
                    <thead>
                        <tr>
                            <th>Quantidade de requisições</th>
                            <th>Usuário</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => (
                            <tr key={row + 1}>
                                <td>{row?.qtt}</td>
                                <td>{row?.user}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default RankingTableUser;
