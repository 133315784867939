import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import TitleC6 from "../../components/Typography/TitleC6";
import RequestByCode from "../../components/Graphs/RequestByCode";
import RequestByUser from "../../components/Graphs/RequestByUser";
import LogTable from "./components/Tables/LogTable";
import RankingTableUser from "./components/Tables/RankingTableUser";
import RankingTableRoute from "./components/Tables/RankingTableRoute";
import ConsultationByPeriod from "../../components/ConsultationByPeriod";
import requests from "../../services/requests";

const AnalyticsPage = () => {
    const filterInformation = useSelector(state => state.filter.value);
    const searchPeriod = useSelector(state => state.period);
    const [searchString, setSearchString] = useState("");
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);

    const getData = () => {
        requests
            .requestSum({
                search: searchString,
                filters: filterInformation,
                period: searchPeriod,
            })
            .then(res => {
                setData(res);
                setLoad(false);
            });
    };

    useEffect(() => {
        getData();
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <Layout>
            <SEO title="Relatório de APIs" />
            <div className="p-4">
                <div className="flex xs:w-full lg:w-100 items-right mb-2 h-20 bg-white">
                    <div className="mt-5">
                        <ConsultationByPeriod />
                    </div>
                    <div className="ml-5 mt-1">
                        <p className="font-normal">Total de requisições: {data.qttRequests}</p>
                        <p className="font-normal">Total de requisições para login com sucesso: {data.qttLoginSuccess}</p>
                        <p className="font-normal">Total de requisições para login com falha: {data.qttLoginFailure}</p>
                    </div>
                </div>
                <Card>
                    <TitleC6>Métricas</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 pb-12 overflow-hidden">
                        <div className="flex mt-5">
                            <div className="w-full">
                                <h6 className="">Quantidade de requisições por Status Code</h6>
                                <RequestByCode
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                                <h6 className="mt-10">Quantidade de requisições por Perfil</h6>
                                <RequestByUser
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-full">
                                <h6 className="mt-10">Ranking requisição por usuário</h6>
                                <RankingTableUser
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                            </div>
                            <div className="w-full">
                                <h6 className="mt-10">Ranking requisição por rota e metódo</h6>
                                <RankingTableRoute
                                    filterInformation={filterInformation}
                                    searchString={searchString}
                                    searchPeriod={searchPeriod}
                                />
                            </div>
                        </div>
                        <div className="w-full mt-5">
                            <h6 className="mt-10">Listagem de logs</h6>
                            <LogTable
                                filterInformation={filterInformation}
                                searchString={searchString}
                                searchPeriod={searchPeriod}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default AnalyticsPage;
